// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import stickybits from "stickybits";

// init stickybits
stickybits(".header-block", {
    noStyles: true,
    useStickyClasses: true,
});
